import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import HtmlParser from '../HtmlParser/HtmlParser';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { media } from '../../utils/mediaQuery';
import { linkhover } from '../../utils/lineHover';
import { ArrowLinkSmall } from '../ArrowLink/ArrowLink';

const Wrapper = styled.div`
  position: relative;

  ${media.large`
    margin: 82px 0 0;
  `}
`

const Copy = styled.div`
  ${fontstack.default}
  ${type('m-body')}
  color: #383B23;

  ${media.large`
    ${type('body')}
  `}
`

const CallToAction = styled.div`
  margin: 15px 0 0;

  ${media.large`
    margin: 30px 0 0;
  `}
`

const ArrowLinkMod = styled(ArrowLinkSmall)` 
  position: relative;
  ${linkhover('#383B23')}

`

const CategoryDescription = (props) => {
  const { children, label } = props; 
  return (
    <Wrapper>
      <Copy>
        <HtmlParser>{ children }</HtmlParser>
      </Copy>
      {label ? <CallToAction><Link to={label.to}><ArrowLinkMod>{label.text}</ArrowLinkMod></Link> </CallToAction>: null }
    </Wrapper>
  )
}

export default CategoryDescription;