import * as React from "react"
import { graphql } from "gatsby"
import { Layout } from "../../../components/Layout/layout"
import { Seo } from "../../../components/seo"
import styled from 'styled-components';
import Section from "../../../components/Section/Section";
import PlpBanner from "../../../components/PlpBanner/PlpBanner";
import PlpProduct from "../../../components/PlpProduct/PlpProduct";
import icebar from '../../../components/PlpProduct/assets/Detox_Bar.jpg';
import icebarBlur from '../../../components/PlpProduct/assets/Detox_Bar_blur.jpg';
import icebarSmoothie from '../../../components/PlpProduct/assets/Detox_Smoothie.jpg';
import CategoryDivider from "../../../components/CategoryDivider/CategoryDivider";
import CategoryDescription from '../../../components/CategoryDescription/CategoryDescription';

const CategoryWrapper = styled.section` 
  position: relative;
`

const CategoryProducts = styled.div`
  position: relative;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  display: grid;
  grid-column-gap: 30px;
  grid-row-gap: 90px;
`

const OverflowHidden = styled.div`
  overflow: hidden;
`

export default function ProductTypeIndex({}) {
  const bannerContent = '<strong>Vegan</strong> functional icepops that you can also swiftly <strong>blend/shake</strong> into rejuvenating smoothies.';

  return (
    <Layout plp>
      <Seo title="Icebar" />
      <OverflowHidden>
      <Section none contain>
        <PlpBanner>{bannerContent}</PlpBanner>
      </Section>
      <Section>
        <CategoryWrapper>
          <CategoryDivider title="ICEBAR SMOOTHIES" attributes={[
            'Vegan',
            'Low GI',
            'Source of Fibre',
            'Wholly Natural',
            'Packed with Superfoods'
          ]} twoCol/>
          <CategoryProducts>
            <PlpProduct 
            title='detox' 
            subtitle='Sweet Greens' 
            description='Hints of: Papaya, Mango, Kale' 
            price="$35HKD" 
            image={icebar} 
            placeholder={{
              src: icebarBlur,
              width: 1158,
              height: 795
            }}
            hover={icebarSmoothie} 
            product='Product' 
            category='Category'/>
            <PlpProduct 
            title='detox' 
            subtitle='Sweet Greens' 
            description='Hints of: Papaya, Mango, Kale' 
            price="$35HKD" 
            image={icebar} 
            placeholder={{
              src: icebarBlur,
              width: 1158,
              height: 795
            }}
            hover={icebarSmoothie} 
            product='Product' 
            category='Category'/>
            <PlpProduct 
            title='detox' 
            subtitle='Sweet Greens' 
            description='Hints of: Papaya, Mango, Kale' 
            price="$35HKD" 
            image={icebar} 
            placeholder={{
              src: icebarBlur,
              width: 1158,
              height: 795
            }}
            hover={icebarSmoothie} 
            product='Product' 
            category='Category'/>
          </CategoryProducts>
        </CategoryWrapper>
      </Section>
      </OverflowHidden>
    </Layout>
  )
}

/*
export const query = graphql`
  query($productType: String!) {
    products: allShopifyProduct(
      filter: { productType: { eq: $productType } }
      sort: { fields: publishedAt, order: ASC }
      limit: 24
    ) {
      nodes {
        ...ProductCard
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`
*/